export default {
  journeyTerm: {
    "Ascent": "journey",
    "Learning Center": "course"
  },
  journeysTerm: {
    "Ascent": "journeys",
    "Learning Center": "courses"
  },
  excursionTerm: {
    "Ascent": "Excursion",
    "Learning Center": "Topic"
  },
  pathTerm: {
    "Ascent": "Path",
    "Learning Center": "Objective"
  },
  progressPicture: {
    Ascent: {
      src: "static_content/progress_image.png",
      size: "mini",
      alt: "Ascent Mountain Progress Image"
    }
  },
  navLogo: {
    "Ascent": {
      src: "static_content/Ascent_Logo_Stacked.png",
      alt: "Ascent logo"
    },
    "Learning Center": {
      src: "https://dev.eli.ussba.io/learning-center/sba-logo.png",
      alt: "SBA logo",
    }
  },
  navLogoLink: {
    "Ascent": "/",
    "Learning Center": "http://www.sba.gov/"
  },
  dashboardTitle: {
    "Ascent": "Ascent Dashboard",
    "Learning Center": "Learning Center Dashboard"
  },
  dashboardDescription: {
    "Ascent": "The Ascent dashboard is a landing page for your personal journey",
    "Learning Center": "Learning Center Dashboard"
  },
  journeysTitle: {
    "Ascent": "Ascent Journeys",
    "Learning Center": "Learning Center Courses",
  },
  journeysDescription: {
    "Ascent": "Navigate through the journeys that best match your business goals",
    "Learning Center": "Learning Center Courses",
  },
  externalSiteModal: {
    Ascent: {
      image: {
        src: "static_content/Ascent_Logo_Stacked(1).png",
        alt:
          "Ascent Logo - a stylized letter 'A', followed by the tagline: 'Ascent: elevate your business'",
        size: "small",
      },
      headerText: "You're leaving the Ascent website.",
      bodyText: `
        This link is provided for your reference only. The SBA doesn't
        endorse non-government websites, companies, or applications. The
        SBA doesn't attest to the accuracy of information provided by
        third-parties and other linked sites.
      `,
    },
    "Learning Center": {
      image: {
        src: "static_content/SBA-Logo-Stacked.png",
        alt: "SBA Logo - U.S. Small Business Administration",
        size: "small",
      },
      headerText: "You're leaving the Learning Center.",
      bodyText: `
        This link is provided for your reference only. The SBA doesn't
        endorse non-government websites, companies, or applications. The
        SBA doesn't attest to the accuracy of information provided by
        third-parties and other linked sites.
      `,
    },
  },
};
