import React, { Component } from "react";
import { Header } from "semantic-ui-react";
import DynamicImage from "../HelperComponents/DynamicImage";
import { withTranslation } from 'react-i18next';

import "./Footer.scss";

class Footer extends Component {
    render() {
        const { t } = this.props;

        return (
            <div id = "footer" tabIndex="-1" className="ui inverted vertical footer segment">
              <div className="ui container">
                <div className="ui stackable inverted equal height equal width grid sba-links-grid">
                  <div className="column">
                    <Header as="h3" inverted>{ t('customerService') }</Header>
                    <div className="ui inverted link list">
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba">{ t('aboutSBA') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/what-we-do/contact-sba">{ t('contactSBA') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/bienvenido-a-la-en-espanol">SBA en Español</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-newsroom">{ t('mediaAndPressRelations') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-locations">{ t('sbaLocations') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-team">{ t('sbaTeam') }</a>
                    </div>
                  </div>
                  <div className="column">
                    <Header as="h3" inverted>{ t('aboutSbaDotGov') }</Header>
                    <div className="ui inverted link list">
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/sitemap">{ t('siteMap') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/about-sbagov-website/privacy-policy">{ t('privacyPolicy') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/about-sbagov-website/linking-policy">{ t('linkingPolicy') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/about-sbagov-website/accessibility">{ t('accessibility') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/about-sbagov-website/disclaimer">{ t('disclaimers') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/tools/events#events-page">{ t('socialMedia') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/digital-sba/open-data">{ t('dataStore') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/blogs">{ t('blog') }</a>
                    </div>
                  </div>
                  <div className="column">
                    <Header as="h3" inverted>{ t('sbaInformation') }</Header>
                    <div className="ui inverted link list">
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/foia">{ t('freedomOfInformationAct') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/about-sbagov-website/no-fear-act">{ t('noFearAct') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/category/navigation-structure/contracting/contracting-officials/report-fraud-waste-abuse">{ t('fraudAndAbuse') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-initiatives">{ t('initiatives') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government/other-plans-reports/plain-language-page">{ t('plainLanguage') }</a>
                    </div>
                  </div>
                  <div className="column">
                    <Header as="h3" inverted>{ t('sbaPerformance') }</Header>
                    <div className="ui inverted link list">
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/strategic-planning">{ t('strategicPlanning') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/performance-budget-finances">{ t('performanceBudgetAndFinancing') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/open-government">{ t('openGovernment') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/policy-regulations">{ t('policyAndRegulation') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/about-sba/sba-performance/policy-regulations/eliminating-fraud-waste-abuse">{ t('eliminatingFraudWasteAndAbuse') }</a>
                    </div>
                  </div>
                  <div className="column">
                    <Header as="h3" inverted>{ t('oversight') }</Header>
                    <div className="ui inverted link list">
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/office-of-inspector-general">{ t('inspectorGeneral') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/advocacy">{ t('advocacy') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/oha">{ t('hearingsAndAppeals') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/ombudsman">{ t('ombudsman') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.whitehouse.gov">{ t('whitehouseDotGov') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.usa.gov">{ t('usaDotGov') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.regulations.gov">{ t('regulationsDotGov') }</a>
                    </div>
                  </div>
                  <div className="column">
                    <Header as="h3" inverted>{ t('toolsAndFeatures') }</Header>
                    <div className="ui inverted link list">
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/tools/sba-learning-center/search/training">{ t('onlineTraining') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/tools/business-plan">{ t('createABusinessPlan') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/events/find/">{ t('findEvents') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/size-standards">{ t('qualityForGovernmentContracts') }</a>
                      <a className="item" target="_blank" rel="noopener noreferrer" href="https://www.sba.gov/videos">{ t('sbaVideos') }</a>
                    </div>
                  </div>
                </div>
                <div className="ui inverted grid">
                  <div className="sixteen wide column sba-footer-divider-grid">
                    <div className="ui divider"></div>
                  </div>
                </div>
                <div className="ui inverted grid stackable">
                  <div id="socials" className="eight wide column">
                    <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/SBAgov" ><i role = "img" aria-label={ t('facebookAria') }  alt={ t('facebookAria') } className="facebook icon large"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/sbagov" ><i role = "img" aria-label={ t('xAria') } alt={ t('xAria') } className="twitter icon large"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://youtube.com/user/sba" ><i role = "img" aria-label={ t('sbaYoutubeAria') } alt={ t('sbaYoutubeAria') } className="youtube icon large"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/company/us-small-business-administration" ><i role = "img" alt={ t('sbaLinkedInAria') } aria-label={ t('sbaLinkedInAria') } className="linkedin icon large"></i></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/sbagov" ><i role = "img" aria-label={ t('sbaInstagramAria') } alt={ t('sbaInstagramAria') } className="instagram icon large"></i></a>
                  </div>
                  <div id="sba-footer-logo-column" className="eight wide column right aligned">
                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.sba.gov" ><DynamicImage alt={ t('sbaAria') } id="sba-footer-logo" size="medium" src="static_content/SBA-Logo-Horizontal-Reverse.png"/></a></p>
                  </div>
                </div>
              </div>
            </div>
            
        );
    }
}

export default withTranslation()(Footer);