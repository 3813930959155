import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { updateDemographicDetails } from '../../actions/demographicActions';
import CheckboxQuestion from '../CheckboxQuestion/CheckboxQuestion';
import GriddedQuestion from './GriddedQuestion';
import OptionalForm from './OptionalForm';

export class DemographicForm extends React.Component {
  updateState(key, value) {
    this.props.dispatch(updateDemographicDetails(key, value));
  }

  inBusinessValue() {
    if(this.props.inBusiness === "Yes") {
      return true;
    } else {
      return false;
    }
  }


  render() {
    const { t } = this.props;

    return (
      <OptionalForm nextButton={this.inBusinessValue()} registerButton={!this.inBusinessValue()}>
        <GriddedQuestion number="1">
          <CheckboxQuestion label={ t('inBusiness.label') } onlyAllowOne={true}
                            answers={[
                              t('inBusiness.options.yes'), 
                              t('inBusiness.options.no')
                            ]}
                            value={this.props.inBusiness}
                            onChange={(value) => this.updateState("in_business", value)}
                            autoFocus={true}/>
        </GriddedQuestion>
        <GriddedQuestion number="2">
          <CheckboxQuestion label={ t('genderIdentity.label') } onlyAllowOne={true}
                            answers={[
                              t('inBusiness.options.male'),
                              t('inBusiness.options.female'),
                              t('inBusiness.options.notListed'),
                              t('inBusiness.options.nonBinary'),
                              t('inBusiness.options.preferToSelfDescribe'),
                              t('inBusiness.options.preferNotToDisclose')
                            ]}
                            value={this.props.gender}
                            onChange={(value) => this.updateState("gender", value)} />
        </GriddedQuestion>
        <GriddedQuestion number="3">
          <CheckboxQuestion label={ t('considerMyself.label') }
                            answers={[
                              t('considerMyself.options.americanIndianOrAlaskaNative'),
                              t('considerMyself.options.asian'),
                              t('considerMyself.options.blackOrAfricanAmerican'),
                              t('considerMyself.options.nativeHawaiianOrOtherPacificIslander'),
                              t('considerMyself.options.white'),
                            ]}
                            value={this.props.race}
                            onChange={(value) => this.updateState("race", value)}/>
        </GriddedQuestion>
        <GriddedQuestion number="4">
          <CheckboxQuestion label={ t('myEthnicity.label') } onlyAllowOne={true}
                            answers={[
                              t('myEthnicity.options.hispanicOrLatino'),
                              t('myEthnicity.options.notHispanicOrLatino'),
                              t('myEthnicity.options.preferNotToDisclose')
                            ]}
                            value={this.props.ethnicity}
                            onChange={(value) => this.updateState("ethnicity", value)}/>
        </GriddedQuestion>
      </OptionalForm>
    )
  }
}

const mapStateToProps = store => {
  return {
    gender: store.demographic.demographicDetails.gender,
    race: store.demographic.demographicDetails.race,
    ethnicity: store.demographic.demographicDetails.ethnicity,
    inBusiness: store.demographic.demographicDetails.in_business
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(DemographicForm)));
